:root{
    --success: #007a33;
    --danger:#da4141;
    --info:rgb(6 0 122);
    --warning:#ffc601;
    --heart:#f3103c;


    --colorPrincipal:#6aaa6e;
    --backgroundColor:#f7f7f7;
    --backgroundElement:#fff;

    --fontColor:rgb(31, 31, 31);
    --fontColorForm: rgb(41, 41, 41);
}


.success{
    background-color: var(--success);
    color: #fff;
}

.success svg{
    color:#fff !important;
    fill:#fff !important;
}

.success path{
    stroke:#fff !important;

}

.outlineSuccess{
    background-color: transparent;
    color: var(--success);
    border:1px solid var(--success)
}


.outlineSuccess svg{
    color:var(--success) !important;
    fill:var(--success) !important;
}

.outlineSuccess path{
    stroke:var(--success) !important;

}



.outlineDanger{
    background-color: transparent;
    color: var(--danger);
    border:1px solid var(--danger)
}

.outlineWarning{
    background-color: transparent;
    color: var(--warning);
    border:1px solid var(--warning)
}


.danger{
    background-color: var(--danger);
    color: #fff;

}

.info{
    background-color: var(--info);
    color: #fff;
}

.warning{
    background-color: var(--warning);
    color:#fff
}

.colorFacebook{
    color:#135cbb !important
}
.colorTwitter{
color:#1da1f2 !important
}
.colorWhatsapp{
color:#1bd741 !important
}