
/* formulario de reseña  */

.formularioResenaContainer {
    padding-top: 10vh;
    padding-bottom: 30vh;
    padding-left: 25%;
    padding-right: 25%;
  }
  
  .formularioResenaContainerTitulo > h1 {
    color: var(--colorPrincipal);
    border-bottom: 5px solid var(--colorPrincipal);
    font-size: 3rem;
  }
  
  .formularioResenaSection {
    margin-top: 100px;
  }
  
  #textareaComentario {
    background-color: transparent;
    border: 0px;
    border-left: 3px solid var(--colorPrincipal);
    color: var(--colorPrincipal);
    font-size: 2rem;
  }
  
  .formularioNoReservableSection input,
  .formularioNoReservableSection textarea,
  .formularioNoReservableSection select {
    background-color: transparent;
    border: 0px;
    border-bottom: 3px solid var(--colorPrincipal);
    color: var(--colorPrincipal);
    font-size: 1.5rem;
    position: relative;
    width: 85% !important;
    margin-left: 2.5%;
  }
  
  .btnSatisfaccion {
    border: 1px solid var(--colorPrincipal);
    font-size: 1.3rem;
    margin: 15px;
    border-radius: 3px;
    color: var(--colorPrincipal);
    background-color: transparent;
    padding: 10px 15px;
    transition: all var(--transition);
  }
  
  .btnSatisfaccionSuccess {
    color: #fff !important;
    background-color: var(--colorPrincipal) !important;
  }

  #containerResena{
      margin-left: -230px;
}

@media (max-width: 500px) {

    .formularioResenaContainer { 

        padding: 0px;
    }
}

