.sideBar{
    width: 230px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #2a2a2a;
    color:#fff;
}

.sideBarBrand{
    text-align: center;
    padding: 15px;
    display: none;

}
.sideBarBrand img{
    width: 150px;
}

.sideBarMenu{

    padding-top:86px;

}


.sideBarMenuElement{
    text-decoration: none;
    padding: 20px;
    text-align: justify;
    color: #fff;
    transition: all var(--transition);
}

.sideBarMenuElement:hover{
    background-color: #3e3e3e;
    color:var(--colorPrincipal)
}

.topNavMenuElement{
display: inline-block;
padding:10px;
transition: all var(--transition);
border-radius: var(--borderRadius);
margin-left: 5px;
cursor: pointer;
}

.topNavMenuElement:hover{

    background-color: var(--colorPrincipal);
    color:#fff
}